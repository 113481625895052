'use client';

import { type HTMLAttributes } from 'react';

import { AlertIcon } from 'components/Icons';
import { cn } from 'utils/tailwind';

interface ErrorMessageProps
  extends Omit<HTMLAttributes<HTMLDivElement>, 'children'> {
  error?: Error;
  resetError?: VoidFunction;
}

const ErrorMessage = ({
  className,
  error,
  resetError,
  ...props
}: ErrorMessageProps) => (
  <div
    {...props}
    className={cn(
      'flex flex-col items-center justify-center px-4 py-20 font-bold text-island-dark md:py-28',
      className
    )}
  >
    <AlertIcon className="mb-2 size-8" />
    <p>Something went wrong. </p>
    <p>Please try again in a few minutes.</p>
    {error?.message && process.env.NEXT_PUBLIC_APP_ENV !== 'production' && (
      <>
        <p>Error Name: {error.name}</p>
        <p>Error Message: {error.message}</p>
      </>
    )}
    {typeof resetError === 'function' && (
      <button
        className="mt-5 w-fit rounded-full border-2 border-solid border-white bg-island-900 px-3 py-1 font-heading text-[12px] font-semibold leading-5 text-white hover:bg-island-700 focus-visible:ring-2 focus-visible:ring-focus focus-visible:ring-offset-2 active:bg-island-600 disabled:pointer-events-none data-loading:pointer-events-none"
        onClick={() => resetError()}
      >
        Try again
      </button>
    )}
  </div>
);

export default ErrorMessage;
