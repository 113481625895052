'use client'; // Error boundaries must be Client Components

import { datadogRum } from '@datadog/browser-rum';
import { useEffect } from 'react';

import ErrorMessage from 'components/Error/ErrorMessage';

export interface ErrorBoundaryPageProps {
  error: Error & { digest?: string };
  reset: VoidFunction;
}

export default function ErrorBoundaryPage({
  error,
  reset,
}: ErrorBoundaryPageProps) {
  useEffect(() => {
    // Log the error to Datadog RUM
    datadogRum.addError(error);
    console.error(error);
  }, [error]);

  return <ErrorMessage error={error} resetError={reset} />;
}
